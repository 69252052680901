import { HTMLAttributes, HTMLInputTypeAttribute } from "react"

interface Props extends HTMLAttributes<HTMLInputElement> {
    name: string
    placeholder: string,
    labelName: string
    type: HTMLInputTypeAttribute,
    error?: string
}

function CustomInput({ name, placeholder, labelName, type, error, ...rest }: Props) {
    return (
        <div className="form-control">
            <label htmlFor="phone">{labelName}</label>
            <input
                name={name}
                spellCheck="false"
                type={type}
                id="phone"
                placeholder={placeholder}
                {...rest}
            />
            <small>{error}</small>
        </div>
    )
}

export default CustomInput