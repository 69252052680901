import styled from "styled-components"

interface Props {
  color?: CSSStyleDeclaration['color']
  width?: number
  height?: number
  borderColor?: CSSStyleDeclaration['color']
  full?: boolean
  margin?: string
}

function Loading({ color = '#fe6f42', width = 45, height = 45, borderColor = 'rgba(5, 6, 30, 0.1)', full, margin }: Props) {

  return <Wrapper style={{
    margin
  }} full={full} color={color} className="loader" width={width} height={height} borderColor={borderColor}>
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  </Wrapper>

}

export default Loading

const Wrapper = styled.div<{ width: number, height: number, color: CSSStyleDeclaration['color'], borderColor: CSSStyleDeclaration['color'], full?: boolean }>`
    grid-column: 1/-1;
    ${({ full }) => full ? `  width: 100%;` : ""}
    display: flex;
    align-items: center;
    justify-content: center;

.loader {
    width: ${({ width }) => width + "px"};
    height: ${({ height }) => height + "px"};
    display: inline-block;
    padding: 0px;
    border-radius: 100%;
    border: 5px solid;
    border-top-color: ${({ color }) => color};
    border-bottom-color:  ${({ borderColor }) => borderColor};
    border-left-color: ${({ color }) => color};
    border-right-color: ${({ borderColor }) => borderColor};
    -webkit-animation: loader4 1s ease-in-out infinite;
    animation: loader4 1s ease-in-out infinite;
}
@keyframes loader4 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes loader4 {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

`