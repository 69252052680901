import { QueryParams } from "@/pages/[slug]";

export function generateQueryString(params: Partial<QueryParams>): string {
  const parts = Object.entries(params).flatMap(([key, values]) => {
    if (Array.isArray(values)) {
      if (values.length === 0) return [];
      return values.map(
        (value) => `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`
      );
    } else if (values !== null && values !== undefined) {
      return [`${encodeURIComponent(key)}=${encodeURIComponent(values)}`];
    }
    return [];
  });

  return parts.length > 0 ? `&${parts.join("&")}` : "";
}

import { ICartList, ICategoryMenu, ICities, IData } from "@/types/global.types";
import { AxiosError } from "axios";

interface GroupedData {
  id: number;
  uuid: string;
  total: number;
  discount: number;
  shipping_charges: string;
  stores: {
    name: string;
    store_id: number;
    items: ICartList["items"];
  }[];
}

export function sortObjectAccordingStore(data: ICartList): GroupedData {
  const groupedData: GroupedData = {
    id: data.id,
    uuid: data.uuid,
    total: data.total,
    discount: data.discount,
    shipping_charges: data.shipping_charges,
    stores: [],
  };

  const storeMap: { [key: string]: GroupedData["stores"][0] } = {};

  data.items.forEach((item) => {
    const storeName = item.store.name;
    if (!storeMap[storeName]) {
      storeMap[storeName] = {
        name: storeName,
        store_id: item.store.id,
        items: [],
      };
      groupedData.stores.push(storeMap[storeName]);
    }

    storeMap[storeName].items.push(item);
  });

  return groupedData;
}

interface ISortCities {
  label: string;
  value: number;
}

export function sortCities(
  data: IData<ICities[]> | undefined
): ISortCities[] | undefined {
  return data?.data.map((item) => ({ label: item.name, value: item.id }));
}

export function calcDiscount(
  dataPrice?: number | string,
  dataDiscount?: number | string,
  discount_type?: "1" | "2" | null
) {
  const price = parseFloat(String(dataPrice));
  const discount = parseFloat(String(dataDiscount));

  if (discount_type === "1") {
    return {
      discount,
      price: (price - discount).toFixed(2),
    };
  } else if (discount_type === "2") {
    return {
      price: (price - (price / 100) * discount).toFixed(2),
      discount,
    };
  } else {
    return {
      price: dataPrice,
      discount: null,
    };
  }
}

export const isActiveLink = (
  path: string,
  href: string,
  className?: string
) => {
  if (path.includes(href)) {
    return className || "tab-title--active";
  }
};

export const sortError = (
  mainError: boolean,
  type: string,
  error: AxiosError<any, any>
) => {
  if (error?.response?.data?.errors[type]) {
    return error?.response?.data?.errors[type];
  } else if (mainError) {
    return error?.response?.data?.errors[0];
  }
};
