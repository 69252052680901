import Layout from "@/components/Tools/Layout/Layout";
import Loading from "@/components/Tools/Loading/Loading";
import CustomInput from "@/components/Ui/Shared/CustomInput/CustomInput";
import { IRegisterParams, Locale } from "@/types/global.types";
import useLogin from "@/hooks/pages/Login/useLogin";
import Cookie from "@/utils/cookies.utils";
import Link from "next/link";
import { useRouter } from "next/router";
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { sortError } from "@/helper/helper";
import { GetServerSideProps } from "next";
import nookies from "nookies"
import { ssrReq } from "@/services/requests/requests";
import { useSearchParams } from "next/navigation";
import AppTranslation from "@/components/Tools/AppTranslation/AppTranslation";
import translate from "@/utils/translate.utils";

interface Props {
    isAuth: false
    locale: Locale
}

function Login({ locale }: Props) {

    const { push } = useRouter()
    const params = useSearchParams()
    const slug = params.get('from')

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IRegisterParams>()

    const { mutate, isLoading, error } = useLogin()

    const onSubmit: SubmitHandler<IRegisterParams> = (data) => {
        !isLoading && mutate({ ...data, uuid: Cookie.get('uuid') }, {
            onSuccess: (data) => {
                Cookie.set({
                    name: 'access_token',
                    val: data.data.access_token,
                })
                push({
                    pathname: slug ? `/${slug}` : `/account/order`,
                })
            }
        })
    }





    return (
        <Layout>
            <div className="register-container">
                <div className="register-card">
                    <div className="login">
                        <div className="top">
                            <div className="header">
                                <p className="title">{translate('welcome', locale)}</p>
                                <p className="subtitle">
                                    {translate('registerContent', locale)}
                                </p>
                            </div>
                            <div className="tabs-titles">
                                <Link className="tab-title tab-title--active" href={`/login${slug ? `?from=${slug}` : ``}`}>
                                    {translate('logIn', locale)}
                                </Link>
                                <Link className="tab-title" href={`/register${slug ? `?from=${slug}` : ``}`}>
                                    {translate('register', locale)}
                                </Link>
                            </div>
                        </div>
                        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <CustomInput type="text"  {...field} labelName={translate('email', locale)} placeholder={translate('email', locale)} error={sortError(false, 'email', error)} />
                                )}
                            /> <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <CustomInput type="password"  {...field} labelName={translate('password', locale)} placeholder={translate('password', locale)} error={sortError(true, 'password', error)} />
                                )}
                            />
                            <button type="submit" className="submit-btn">
                                {isLoading ? <Loading width={40} height={40} borderColor="#fff" /> : "Daxil ol"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AppTranslation(Login);


export const getServerSideProps: GetServerSideProps = async (ctx) => {

    try {
        const cookies = nookies.get(ctx)
        const isAuth = cookies.access_token
        await ssrReq.getProfile(isAuth)

        return {
            redirect: {
                permanent: false,
                destination: "/account/order"
            }
        }
    } catch (error) {
        return {
            props: {
                isAuth: false
            }
        }
    }

}