import { ILoginParams } from "@/types/global.types";
import { pages } from "@/services/requests/requests";
import { useMutation } from "react-query";
import Cookie from "@/utils/cookies.utils";
import { AxiosError } from "axios";

function useLogin() {
  const { mutate, isLoading, error } = useMutation(
    (params: ILoginParams) => pages.postLogin(params),
    {
      onSuccess: () => {
        Cookie.delete("uuid");
      },
    }
  );

  const _error = error as AxiosError<any, any>;

  return { mutate, isLoading, error: _error };
}

export default useLogin;
